.equipment-details > div {
  max-width: 100%;
  width: 400px;
  display: flex;
}

.equipment-details > div > span {
  width: 50%;
}

@media (max-width: 500px) {
  .equipment-details > div {
    display: block;
    margin-bottom: 15px;
  }

  .equipment-details > div > span {
    width: 100%;
    display: block;
  }
}

.app-main-drawer .MuiDrawer-paper {
  justify-content: space-between;
}

.qune-up-print-logo {
  /* display: none; */
  display: block;
  width: 100px;
  margin-top: 5px;
  margin: 0 auto;
}
